import "./Card.css";

import React from "react";

import { useNavigate } from "react-router-dom";

export default function Card({
  article: { id, title, image, imageAltText, category, date },
}) {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/article/" + id);
  }
  return (
    <div className="card" onClick={handleClick} style={{ cursor: "pointer" }}>
      <img className="card-image" src={image} alt={imageAltText} />
      <div className="text-container">
        <h4>{title}</h4>
        <p>{category}</p>
        <i>{date}</i>
        {/*TODO: change the category to be a pill later */}
      </div>
    </div>
  );
}
