import "./Navbar.css";

import React from "react";

import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Resume from "./Assets/MatthewKee-Resume.pdf";

export default function Navbar() {
  return (
    <nav className="nav">
      <a className="icon-container" href="/">
        <FontAwesomeIcon icon={faKey} size="2x" style={{ color: "#fca311" }} />
      </a>
      <ul>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a
            href={Resume}
            without="true"
            rel="noopener noreferrer"
            target="_blank"
          >
            Resume
          </a>
        </li>
      </ul>
    </nav>
  );
}
