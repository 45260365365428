import "./Article.css";

import React, { useEffect } from "react";

import { useParams } from "react-router";

import { articleData } from "../Articles/Data";

export default function Article() {
  const { id } = useParams();
  const dataForCurrentArticle = articleData.find(
    (article) => article.id === id
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="article">
      <div>
        <h1>{dataForCurrentArticle.title}</h1>
        <p>
          {dataForCurrentArticle.category} | {dataForCurrentArticle.date}
        </p>
      </div>
      <img
        src={dataForCurrentArticle.image}
        alt={dataForCurrentArticle.imageAltText}
      ></img>
      <div className="content"> {dataForCurrentArticle.content}</div>
    </div>
  );
}
