import './Experience.css';

import React from 'react';

import headshot2023 from '../../Assets/Headshot2023.jpg';

export default function Experience() {
  return (
    <div className="experience-container">
      <img src={headshot2023} alt={"Headshot of me!"} />
      <div className="experience">
        <h3>
          <span className="highlight">True story:</span> I spend more time
          working on other peoples' resumes than I do on my own!
        </h3>
        <br />
        <p>
          As a 4th-year engineering student at the University of Waterloo, I
          critique dozens of resumes every year and mentor first-year students
          based on my experience with
          <span className="highlight"> 4 years of job searches</span> and
          <span className="highlight"> 6 co-ops in software development</span>.
        </p>
        <br />
        <p>
          I wanted to create an online resource to help first-years, so here are
          my answers to the most common questions & concerns!
        </p>
      </div>
    </div>
  );
}
