import "./Home.css";

import React from "react";

import { articleData } from "../../Articles/Data";
import ArticleList from "./ArticleList";
import Experience from "./Experience";
import HomeHeader from "./HomeHeader";

export default function Home() {
  return (
    <div className="home">
      <HomeHeader />
      <Experience />
      {/* Pills for searching */}
      <ArticleList articles={articleData} />
    </div>
  );
}
