import React from "react";
import { Outlet } from "react-router-dom";

import "./Root.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Root() {
  return (
    <div className="root">
      <div className="root-container">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}
