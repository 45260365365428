import { useRouteError } from "react-router-dom";

import "./ErrorPage.css";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div class="error-page">
      <h1>Oh dear.</h1>
      <p>
        Sorry, an unexpected error has occurred! Text me the error code and
        maybe I can fix this!
      </p>
      <p>
        Error code: <i> {error.statusText || error.message}</i>
      </p>
    </div>
  );
}
