import { placesToEatVancouver } from "./PlacesToEatVancouver";

export const articleData = [
  placesToEatVancouver,
  // {
  //   id: "how-to-write-a-resume",
  //   title: "How to write a resume",
  //   icon1: (
  //     <FontAwesomeIcon
  //       icon={faMountain}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   icon2: (
  //     <FontAwesomeIcon
  //       icon={faSnowflake}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   iconBackgroundColour: "#555975",
  //   image: archeryPhoto,
  //   imageAltText: "Interview Icon",
  //   category: "Job search",F
  //   description: "Text 4",
  // },
  // {
  //   id: "how-to-write-a-cover-letter",
  //   title: "How to write a cover letter",
  //   icon1: (
  //     <FontAwesomeIcon
  //       icon={faMountain}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   icon2: (
  //     <FontAwesomeIcon
  //       icon={faSnowflake}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   iconBackgroundColour: "#4F131E",
  //   imageAltText: "Interview Icon",
  //   category: "Job search",
  //   description: "Text 4",
  // },
  // {
  //   id: "how-to-prepare-for-interviews",
  //   title: "How to prepare for interviews",
  //   icon1: (
  //     <FontAwesomeIcon
  //       icon={faMountain}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   icon2: (
  //     <FontAwesomeIcon
  //       icon={faSnowflake}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   iconBackgroundColour: "#C4C8E8",
  //   imageAltText: "Interview Icon",
  //   category: "Job search",
  //   description: "Text 4",
  // },
  // {
  //   id: "my-top-10-things-to-do-in-Vancouver",
  //   title: "My top 10 things to do in Vancouver",
  //   icon1: (
  //     <FontAwesomeIcon
  //       icon={faMountain}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   icon2: (
  //     <FontAwesomeIcon
  //       icon={faSnowflake}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   iconBackgroundColour: "#d87264",
  //   imageAltText: "Interview Icon",
  //   category: "Tourism",
  //   description: "This will be where my text goes!",
  // },
  // {
  //   id: "my-top-10-places-to-eat-in-Montreal",
  //   title: "My top 10 places to eat in Montreal",
  //   icon1: (
  //     <FontAwesomeIcon
  //       icon={faMountain}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   icon2: (
  //     <FontAwesomeIcon
  //       icon={faSnowflake}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   iconBackgroundColour: "#301C4D",
  //   imageAltText: "Interview Icon",
  //   category: "Food",
  //   description: "Text 3",
  // },
  // {
  //   id: "my-top-10-places-to-eat-in-Toronto",
  //   title: "My top 10 places to eat in Toronto",
  //   icon1: (
  //     <FontAwesomeIcon
  //       icon={faMountain}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   icon2: (
  //     <FontAwesomeIcon
  //       icon={faSnowflake}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   iconBackgroundColour: "#638CF6",
  //   imageAltText: "Interview Icon",
  //   category: "Food",
  //   description: "Text 4",
  // },
  // {
  //   id: "my-top-10-places-to-eat-in-Waterloo",
  //   title: "My top 10 places to eat in Waterloo",
  //   icon1: (
  //     <FontAwesomeIcon
  //       icon={faMountain}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   icon2: (
  //     <FontAwesomeIcon
  //       icon={faSnowflake}
  //       size="4x"
  //       style={{ color: "#fefcfb" }}
  //     />
  //   ),
  //   iconBackgroundColour: "#178581",
  //   imageAltText: "Interview Icon",
  //   category: "Food",
  //   description: "Text 4",
  // },
];
