import brownsSocialHouse from "../Assets/BrownsSocialhouse.JPG";
import brownsSocialHouseDrink from "../Assets/BrownsSocialhouseDrink.JPG";
import cambiePub from "../Assets/CambiePub.jpg";
import communityTapsAndPizza from "../Assets/CommunityTapsAndPizza.JPG";
import kokoroTokyoMazesoba from "../Assets/KokoroTokyoMazesoba.jpg";
import maruhachiRamen from "../Assets/MaruhachiRamen.jpg";
import sushiYan from "../Assets/SushiYan.JPG";
import tacofino from "../Assets/Tacofino.JPG";
import tomSushi from "../Assets/TomSushi.jpg";
import recommendationBuilder from "./RecommendationBuilder";

const sushiYanImage = { url: sushiYan, altText: "Photo of curry pork katsu" };
const maruhachiRamenImage = {
  url: maruhachiRamen,
  altText: "Photo of tamago ramen",
};
const kokoroTokyoMazesobaImage = {
  url: kokoroTokyoMazesoba,
  altText: "Photo of niku mazesoba",
};
const brownsSocialHouseImage = {
  url: brownsSocialHouse,
  altText: "Photo of southwest rice bowl",
};
const brownsSocialHouseDrinkImage = {
  url: brownsSocialHouseDrink,
  altText: "Photo of peach bellini",
};
const communityTapsAndPizzaImage = {
  url: communityTapsAndPizza,
  altText: "Photo of truffle, pepperoni and mushroom pizza",
};
const cambiePubImage = {
  url: cambiePub,
  altText: "Photo of Cambie Pub Karaoke Night poster",
};
const tacofinoImage = {
  url: tacofino,
  altText: "Photo of two tacos",
};

const recommendations = (
  <div>
    {recommendationBuilder(
      [],
      "Tom Sushi",
      "1175 Davie St, Vancouver",
      "tomsushi.ca",
      `The best sushi I've ever had in my entire life. Beautiful restaurant, amazing food. They are extremely popular and
      do not take reservations, so show up during off-hours or expect a 20 to 30-minute wait.`,
      "Black dragon roll ($11.95)",
      "Imitation crab, avocado, and cucumber inside then eel, avocado, fish flakes and fish eggs on top"
    )}
    {recommendationBuilder(
      [sushiYanImage],
      "Sushi Yan",
      "770 Thurlow Street, Vancouver",
      "sushiyanonline.ca",
      `The curry rice with pork cutlet is to die for. My friend Tracy discovered it and she convinced 3 of us to try it.
      All of us loved it. The sushi here is also good (but not as good as Tom Sushi above).`,
      "Curry rice with pork cutlet ($12.95)",
      "Breaded pork cutlet, fried egg, curry sauce, mushrooms, onions, glass noodles, and rice"
    )}
    {recommendationBuilder(
      [maruhachiRamenImage],
      "Maruhachi Ra-men",
      "270 Robson St, Vancouver or 780 Bidwell St, Vancouver",
      "maruhachi.ca",
      `The best ramen I've had in Vancouver by far. I've listed the two locations that are downtown and both are good, 
      so I typically go wherever is closer. I brought 6 friends here and everyone loved it.`,
      "Tamago ramen ($14.40)",
      "Chashu pork, soft-boiled egg, Aosa seaweed, green onion, thin noodles in chicken broth"
    )}
    {recommendationBuilder(
      [kokoroTokyoMazesobaImage],
      "Kokoro Tokyo Mazesoba",
      "Locations near UBC, Kitsilano, and downtown",
      "menyakokoro.com",
      `Mazesoba literally translates to “mixed noodle”. It is a soupless noodle dish with fresh egg yolk and all the 
      best ingredients of ramen. You break the egg yolk, mix the dish together, and add vinegar or chili as needed. 
      It's a delicious non-traditional Asian dish and is definitely worth trying.`,
      "Niku mazesoba ($15.49)",
      `Slow braised pork chashu, spicy minced pork, egg yolk, minced garlic, green onion, seaweed flakes, 
      grinded saba fish, chives, and multi-grain noodle`
    )}
    {recommendationBuilder(
      [brownsSocialHouseImage, brownsSocialHouseDrinkImage],
      "Brown's Socialhouse",
      "Locations near UBC, Point Grey, and downtown",
      "brownssocialhouse.com",
      `The perfect place to go for a classy dinner and a drink. More expensive but worth it. The peach bellini 
      ($8.75, white rum, sparkling wine, sangria and peach schnapps) is a student staple and genuinely tastes like fuzzy
      peaches. The southwest rice bowl is the best burrito bowl I've ever had.`,
      "Southwest rice bowl ($23.50)",
      "Blackened chicken, feta, corn, black beans, guacamole, chipotle, avocado, tomato, crispy tortilla"
    )}
    {recommendationBuilder(
      [communityTapsAndPizzaImage],
      "Community Taps and Pizza",
      "1191 Commercial Drive",
      "vancouver.communitypizza.ca",
      `Perfect for dinner and a show! Every Monday night at 8pm-10pm there's an open mic 
      and poetry slam with some exceptional poets from across Vancouver. The food isn't the cheapest but the pizza's 
      excellent. It's also a bar and they have pretty good beer flights!`,
      "Truffle, pepperoni, and mushroom 14” pizza ($24)",
      "Mozzarella, pepperoni, roasted mushrooms, red sauce, truffle oil"
    )}
    {recommendationBuilder(
      [cambiePubImage],
      "The Cambie Pub",
      "300 Cambie St, Vancouver",
      "cambiepubs.com",
      `A really common place for students to go for drinks. There's karaoke 9pm-1am every Thursday night and a DJ every
      Friday night starting at 10pm. The peach bellinis here are good (but not as good as Brown's Socialhouse above). 
      The fish and chips are perfect drunk food. Note that you'll need two separate pieces of ID to get in 
      (i.e. driver's license/student card/health card).`,
      "Fish and chips ($17)",
      "Breaded cod, house-made tartar sauce and coleslaw with fries or salad"
    )}
    {recommendationBuilder(
      [tacofinoImage],
      "Tacofino",
      "Locations in Kitsilano, downtown, and Gastown",
      "tacofino.com",
      `Really good tacos. Perfect for lunch with a friend. The tacos are pretty big so two tacos will be a meal. `,
      "Fish tacos ($7.75 each, in the right of the picture)",
      "Breaded pacific cod, chipotle mayo, salsa, cabbage"
    )}
  </div>
);

export const placesToEatVancouver = {
  id: "my-top-8-places-to-eat-in-Vancouver",
  title: "My top 8 places to eat in Vancouver",
  image: tomSushi,
  imageAltText: "Picture of sushi",
  category: "Food & travel",
  date: "August 2023",
  content: recommendations,
};
