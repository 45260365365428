import "./About.css";

import React from "react";

import aboutMe from "../Assets/AboutMe.jpeg";

export default function About() {
  return (
    <div className="about">
      <img src={aboutMe} alt={"Me in a garden"}></img>
      <h1>About</h1>
      <p>
        Hi, I'm Matthew! I was born and raised in Toronto, where I grew up
        reading books on the streetcar then subsequently missing my stop and
        being late for school. When I'm on a study term in Waterloo, you can
        find me sleeping in class during my morning lectures (8:30 is too early
        for linear algebra) or shooting at the archery range to unwind.
      </p>
      <p>
        On co-op terms, I pick up and move to a new city to explore! So far I've
        worked in&nbsp;
        <span className="highlight">
          Toronto, Waterloo, Montreal, Vancouver, and Calgary
        </span>
        . I genuinely love software development, and have experience in both
        mobile app development and full-stack web. Most recently, I worked to
        make education more accessible to learners around the world with&nbsp;
        <a
          href={"https://outschool.com/"}
          without="true"
          rel="noopener noreferrer"
          target="_blank"
        >
          Outschool
        </a>
        's online learning platform.
      </p>
      <p>
        In my free time, I write and perform spoken word poetry! I have competed at
        poetry slams and showcases across Canada, including on a national stage
        at the 2023 Canadian Individual Poetry Slam. I love Shakespeare, martial
        arts (Taekwondo and Muay Thai), and&nbsp;
        <a
          href={"https://www.youtube.com/watch?v=rr6f26I2cBI"}
          without="true"
          rel="noopener noreferrer"
          target="_blank"
        >
          funk music
        </a>
        .
      </p>
    </div>
  );
}
