import "./Footer.css";

import React from 'react';

export default function Footer() {
  return (
    <div className="footer">
      <p>
          Made with React and hosted with Firebase
        </p>
    </div>
  );
}
