import React from "react";

import "./ArticleList.css";
import Card from "../../Common/Card";

export default function ArticleList({ articles }) {
  return (
    <div className="article-list">
      {articles.map((article) => (
        <Card article={article} key={article.id} />
      ))}
    </div>
  );
}

// TODO: Figure out why it doesn't change to 2 columns
