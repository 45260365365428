import "./Variables.css";

import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import About from "./Pages/About.js";
import Article from "./Pages/Article.js";
import ErrorPage from "./Pages/ErrorPage.js";
import Home from "./Pages/Home/Home";
import Root from "./Root.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "article/:id",
        element: <Article />,
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

// import { getAnalytics } from "firebase/analytics";
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAsNPmbQZDcIFjWQETt-fNSdK2wnz0HOPg",
//   authDomain: "kee-notes.firebaseapp.com",
//   projectId: "kee-notes",
//   storageBucket: "kee-notes.appspot.com",
//   messagingSenderId: "962771433806",
//   appId: "1:962771433806:web:9a6bc9520e64453215461f",
//   measurementId: "G-0DE2M5LK30",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
