export default function recommendationBuilder(
  images,
  title,
  address,
  url,
  description,
  recommendationTitle,
  recommendationDescription
) {
  return (
    <div>
      <div>
        {images.length > 0 &&
          images.map((image) => {
            return (
              <img
                className="recommendation-image"
                key={image.url}
                src={image.url}
                alt={image.altText}
              />
            );
          })}
      </div>
      <h3>{title}</h3>
      <i>{address}</i>
      &ensp; | &ensp;
      <a
        href={"https://" + url}
        without="true"
        rel="noopener noreferrer"
        target="_blank"
      >
        {url}
      </a>
      <p>{description}</p>
      <div className="indented">
        <h4>My #1 Recommendation:</h4>
        <p>
          {recommendationTitle}
          <br />
          <i>{recommendationDescription}</i>
        </p>
      </div>
      <hr />
    </div>
  );
}
