import "./HomeHeader.css";

import React from "react";

export default function HomeHeader() {
  return (
    <div className="home-header">
      <h2>Hi! I'm Matthew Kee</h2>
      <h1>Welcome to Kee Notes</h1>
      <p>Resume writing, interview prepping, and other FAQs</p>
    </div>
  );
}
